import * as XLSX from 'xlsx'
import {
  formatarData,
  formatarParaNumber,
  formatarValorReais,
  formatarValorPorcentagem,
  formatarValor,
  PascalCaseComEspaco,
} from '@/libs/utils'

const createSheet = (tableData: any[]) => {
  // Usando os dados e colunas para criar a planilha
  const ws = XLSX.utils.json_to_sheet(tableData)

  const wb = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(wb, ws, 'Relatório Geral')

  // Salvando o arquivo
  XLSX.writeFile(wb, 'relatorio-de-contratos.xlsx')
}

export const asDefault = () => {
  return {
    convertToExcelFormat: (item, formatarData) => {
      const colunas = {
        CLIENTE: String(item.instituto.sigla).toUpperCase() ?? '',
        'CRIADO EM': item.createdAt ? formatarData(item.createdAt) : '',
        CONTRATO: item.codigo ?? '',
        'NOME DO TOMADOR': PascalCaseComEspaco(item.proposta.segurado.tomador.nomeCompleto) ?? '',
        MATRIULA: item.proposta.segurado.matricula ?? '',
        'VALOR DO CONTRATO': formatarParaNumber(item.proposta.valorComIOF) ?? '',
        TAXAS: formatarParaNumber(item.taxas) ?? '',
        'VALOR DA PARCELA': formatarParaNumber(item.valorParcela) ?? '',
        'Nº DE PARCELAS': item.numeroParcelas ?? '',
        'DATA DE CONSOLIDAÇÃO': item.dtInicio ? formatarData(item.dtInicio) : '',
        STATUS: item.statusFinanceiro,
        CATEGORIA: item.proposta.segurado.situacaoPrevidenciaria,
        'VENDEDOR(A)': item.usuario?.name ? PascalCaseComEspaco(item.usuario?.name) : '',
        'SALDO DEVEDOR': formatarParaNumber(item.saldoDevedor) ?? '',
      }

      for (const pendencia of item.Pendencias) {
        const nomeDaPendencia = `Pendência - ${String(pendencia.nomeDaPendencia)} - ${
          pendencia.ListaPendencias.obrigatorio ? 'Obrigatório' : 'Opcional'
        }`
        colunas[nomeDaPendencia] = pendencia.concluido ? 'Concluído' : 'Pendente'
      }

      return colunas

      // for (const dado of item) {
      //   if (dado.Pendencias && dado.Pendencias.length > 0) {
      //     dado.listaDePendencias = dado.Pendencias.map(
      //       (pendencia) =>
      //         `${pendencia.nomeDaPendencia} - ${pendencia.ListaPendencias.obrigatorio ? 'Obrigatório' : 'Opcional'} - ${
      //           pendencia.concluido ? 'Concluído' : 'Pendente'
      //         }`,
      //     ).join(', ')
      //   } else {
      //     dado.listaDePendencias = 'Nenhuma pendência encontrada'
      //   }
      // }
    },
    export: (items) => {
      const situationItems = items.map((item) => asDefault().convertToExcelFormat(item, formatarData))
      createSheet(situationItems)
    },
  }
}
